import React, { Component } from 'react';

import { Container, GlobalStyle } from './style';

class App extends Component {
  render() {
    return (
      <div className="App">
        <GlobalStyle />
        <Container>
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.848 12.46c.202.037.202.332 0 .37-1.906.362-6.044 1.112-6.546 1.112a1.3 1.3 0 0 1-1.3-1.3c0-.513.77-5.448 1.124-7.446.034-.192.312-.18.343.014l.984 6.238 5.394 1.01z'/>
            </svg>
            <h1>Coming soon!</h1>
          </div>
        </Container>
      </div>
    );
  }
}

export default App;
