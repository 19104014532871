import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: Lato, "Segoe UI", Avenir, Ubuntu, Tahoma, Verdana, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: 100vh;
  justify-content: center;
  padding: 0 15px;

  h1 {
    font-size: 4em;
    font-weight: normal;
    text-transform: lowercase;
  }

  svg {
    width: 200px;
    height: 200px;
  }
`;
